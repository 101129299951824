//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'

//Packages
import Helmet from 'react-helmet'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

//Components
import Layout from '../components/layout'
import HomeCallouts from '../components/marketing/homeCallouts'
import TemplateCallouts from '../components/marketing/templateCallouts'
import FeatureIcon from '../components/marketing/featureIcon'
import PreviewVideo from '../components/elements/previewVideo'

//Icons
import { FaArrowRight } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Storylava - Engage visually with rich graphics for selling"
          meta={[
            {
              name: 'description',
              content:
                'Storylava helps your business connect visually with customers to drive action and results',
            },
          ]}
        />

        <div class="callout-home d-flex align-items-center justify-content-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-9 d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start ">
                <h1 class="text-center text-md-left font-weight-bold">
                  Create content, socially
                </h1>
                <h2 class="lead text-center text-md-left display-4">
                  Discover, remix, and share social content to engage customers
                  and drive your business
                </h2>
                <Link
                  to="/request-invite/"
                  class="btn btn-primary btn-cta-outline mt-4"
                >
                  <div>
                    Request invite <FaArrowRight class="btn-arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div class="overlay"></div>
          <StaticImage
            src="../images/feature/storylava-home-ui-composite.png"
            alt="Storylava"
            loading="eager"
            placeholder="blurred"
            layout="fullWidth"
            // width={800}
            // height={400}
            // style={{ position: `absolute` }}
            style={{
              position: `absolute`,
              // top: 0,
              // left: 0,
              // right: 0,
              // bottom: 0,
              inset: 'auto',
              width: '100%',
              height: 400,
              zIndex: 0,
            }}
          />
          {/* <Img
            css={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '400px',
              zIndex: 10,
            }}
            style={{ position: `absolute` }}
            fluid={this.props.data.homebg.childImageSharp.fluid}
          /> */}
        </div>

        {/* <div class="border-bottom-1-yellow-5 bg-yellow-6 py-2">
          <div class="container my-2 py-4">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <h2 class="font-weight-light text-center display-3 black-4">
                    Reduce risk by screening employees &amp; customers for COVID
                    symptoms before they arrive
                  </h2>
                  <Link to="/about/capabilities/" class="btn btn-primary btn-cta mt-2">
                    How it works <FaArrowRight class="btn-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/*         
        <div class="bg-yellow-6">
          <div class="container mt-4 py-2">
            <div class="row d-flex">
              <div class="col-lg-12 d-flex flex-column align-items-center justify-content-center">
                <h3 class="text-center display-3">Mindfulness challenges</h3>

                <div class="col-md-6 m-3 mt-md-0 home-callout-container border-1-yellow-1 rounded-corners">
                  <div class="gradient-bg-home-1"></div>

                  <div
                    class="d-flex flex-column"
                    style={{ position: `relative`, zIndex: 2, height: '100%' }}
                  >
                    <h3 class="text-left font-weight-bold p-3 display-3 white-1">
                      Features
                    </h3>
                    <div
                      class="home-callout-text text-left d-flex flex-column flex-grow-1"
                      style={{ width: '60%', marginRight: '40%' }}
                    >
                      <p class="lead white-1">
                        Branded video containers with content screening,
                        analytics &amp; lead gen
                      </p>
                      <Link
                        to="/about/features/"
                        className="mt-auto mb-2 btn btn-yellow align-self-start"
                      >
                        Discover{' '}
                        <FaArrowRight
                          style={{ marginTop: -1 }}
                          class="btn-arrow"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="callout-home-overview bg-gray-7">
          <div class="container">
            <div class="row">
              <div class="col-sm-5 text-center mt-3 p-2 py-4">
                <StaticImage
                  src="../images/feature/storylava-template-edit-standalone.png"
                  alt="Storylava"
                  // loading="eager"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </div>
              <div class="callout-home-overview-text col-sm-7 rounded-corners text-center mt-3 p-2 py-4 d-flex flex-column align-items-center justify-content-center">
                <h2 class="text-center font-weight-bold mt-2 mt-lg-0">
                  A design platform, powered by social connections.
                </h2>
                <p class="lead text-center display-4 black-5">
                  Discover new ideas by following amazing designers and remixing
                  their content.
                </p>

                <Link
                  to="/about/capabilities/"
                  class="btn btn-primary btn-cta mt-3"
                >
                  See platform <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="callout-home d-flex align-items-center justify-content-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-9 d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start">
                <h2 class="white-1 font-weight-bold text-center text-md-left">
                  Design. Reinvented by social.
                </h2>
                <p class="lead white-2 line-height-4 mt-3 mb-4 text-center text-md-left">
                  Create rich visual content with mobile-first workflows.{' '}
                  <br class="d-none d-md-inline-block" />
                  And make it easy to drive your business with design, powered
                  by social connections.
                </p>

                <Link to="/about/features/" class="btn btn-cta-outline mt-3">
                  Learn more about social design{' '}
                  <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
          <div class="overlay-sunrise"></div>
          {/* <Img
            css={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '400px',
              zIndex: 10,
            }}
            style={{ position: `absolute` }}
            fluid={this.props.data.templatebg.childImageSharp.fluid}
          /> */}
          <StaticImage
            src="../images/feature/storylava-home-template-composite.png"
            alt="Storylava"
            loading="lazy"
            placeholder="blurred"
            layout="fullWidth"
            // width={800}
            // height={400}
            // style={{ position: `absolute` }}
            style={{
              position: `absolute`,
              // top: 0,
              // left: 0,
              // right: 0,
              // bottom: 0,
              inset: 'auto',
              width: '100%',
              height: 400,
              zIndex: 0,
            }}
          />
        </div>

        {/* <div class="mt-3 mb-5">
          <TemplateCallouts />
          <HomeCallouts />
        </div> */}

        <div class="callout-home-industry pt-2 pb-4 rounded-corners my-3 mx-2 mx-lg-5">
          <div class="container">
            <div class="row d-flex">
              <div class="offset-lg-2 col-md-8 col-lg-6  rounded-corners text-center mt-3 p-2 py-4 d-flex flex-column align-items-center justify-content-center">
                {/* <div class="font-title-callout-sub h5 white-1">Notify me</div> */}
                <h2 class="white-1 font-weight-bold">
                  Social content. Designed for you.
                </h2>
                <p class="lead white-2 line-height-4 mt-3 mb-4">
                  Stay fresh, stay up-to-date, and stay connected with your
                  customers with hyper-relevant content for your industry.
                </p>

                <Link to="/industry/" class="btn btn-cta-outline mt-3">
                  Social design for your industry{' '}
                  <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
              <div class="col-md-3 col-lg-3 text-center mt-3 p-2 py-4">
                <Img fixed={this.props.data.homefollow.childImageSharp.fixed} />
              </div>
            </div>
          </div>
        </div>
        {/* <div class="gradient-callout-cirrus pt-2 pb-4">
          <div class="container">
            <div class="row d-flex">
              <div class="col-md-5 text-center mt-3 p-2 py-4">
                <Img fixed={this.props.data.homedesign.childImageSharp.fixed} />
              </div>
              <div class="col-md-7  rounded-corners text-center mt-3 p-2 py-4 d-flex flex-column align-items-center justify-content-center">
                <h2 class="white-1 font-weight-bold">Find and follow.</h2>
                <p class="lead white-2 line-height-4 mt-3 mb-4">
                  Discover new content and be inspired by designers you follow
                </p>

                <Link to="/features/#social" class="btn btn-cta-outline mt-3">
                  Find and follow <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div> */}

        <div class="callout-home-design gradient-callout-cirrus container-fluid px-0">
          <div class="row no-gutters h-100">
            <div class="col-md-5 d-none d-md-flex justify-content-md-start align-items-md-start">
              <Img
                css={{ overflow: 'visible' }}
                fixed={this.props.data.homedesign.childImageSharp.fixed}
              />
            </div>
            <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="h-100 p-3 d-flex flex-column align-items-center justify-content-center">
                <h2 class="text-center font-weight-bold mt-2 mt-lg-0 white-1">
                  Drive revenue from social
                </h2>
                <p class="lead white-2 line-height-4 text-center mt-3 mb-lg-4">
                  Social channels are the new revenue driver. Discover how
                  Storylava can accelerate your goals.
                </p>
                <Link to="/about/benefits/" class="btn btn-cta-outline mt-3">
                  See how Storylava can help <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="mt-3 mb-5">
          <HomeCallouts />
        </div> */}
        {/* <div class="gradient-callout-warm pt-2 pb-4">
          <div class="container">
            <div class="row d-flex">
              <div class="col-md-12 rounded-corners text-center p-2 py-4">
                <div class="d-flex justify-content-center align-items-center mb-3">
                  <FeatureIcon context="hospital" />
                </div>
                <h2 class="white-1 font-weight-bold">
                  Together, we can all beat Covid-19
                </h2>
                <p class="lead white-2">
                  Let's rally our communities, join together, and rise above
                </p>
                <Link to="/about/covid19-crisis/" class="btn btn-cta-outline">
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    )
  }
}
// duotone: { highlight: "#f5aa00", shadow: "#1d1a08" }

export const pageQuery = graphql`
  query {
    homebg: file(
      relativePath: { eq: "feature/storylava-home-ui-composite.png" }
    ) {
      childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          maxHeight: 600
          cropFocus: NORTHEAST
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    templatebg: file(
      relativePath: { eq: "feature/storylava-home-template-composite.png" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 920, maxHeight: 400, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    homedesign: file(
      relativePath: { eq: "feature/storylava-canvas-share-design-3up.png" }
    ) {
      childImageSharp {
        fixed(width: 505, height: 400, cropFocus: EAST, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    homedesignfluid: file(
      relativePath: { eq: "feature/storylava-canvas-share-design-3up.png" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 950, maxHeight: 600, cropFocus: WEST) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    industryRealestate: file(
      relativePath: { eq: "feature/storylava-template-edit-standalone.png" }
    ) {
      childImageSharp {
        fixed(width: 460, height: 630, cropFocus: CENTER, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    homefollow: file(
      relativePath: { eq: "feature/storylava-follow-designer-standalone.png" }
    ) {
      childImageSharp {
        fixed(width: 350, height: 480, cropFocus: WEST, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
